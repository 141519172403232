import { BrowserRouter, Routes, Route } from "react-router-dom";
import Borrow from './pages/Borrow';
import Lend from './pages/Lend';
import Home from './pages/Home';
import Profile from "./pages/Profile";

function App() {
  return (
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="borrow" element={<Borrow />} />
        <Route path="lend" element={<Lend />} />
        <Route path = "profile" element = {<Profile />} />
        <Route path="*" element={<Home />} />

    </Routes>
  </BrowserRouter>

  );
}

export default App;
