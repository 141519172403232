import React from "react";
import { ethers } from "ethers";
import { useContract, useAddress } from "@thirdweb-dev/react";
import { parseUnits } from "ethers/lib/utils";

function BorrowerProfile (props) {
  const {contract, isLoading} = useContract("0x44cA80B70dF8F5F50478c75A2d3F45E96E17cB1F");
  const fromAddress = useAddress();

  const today = new Date();
  const mseconds = today.getTime();
  const secs = Math.floor(mseconds/1000);
  const secsInADay = 24 * 60 *60;


  const createLoanHandler = async(event) =>{
    event.preventDefault();
    if(fromAddress){
    const toAddress= props.address;
    const requestedAmount = props.requestedAmount;
    const repaymentDate = secs + (2*secsInADay);

    //temporarily using a value of 1 ETH = 1600 USD to force contract creation for a value of 0.001 eth
    const ethAmount = parseUnits(requestedAmount.toString());

    const result = await contract.call("createLoan", fromAddress, toAddress, ethAmount, repaymentDate);
    console.log(result);
    } else{
      alert("Please make sure your wallet is connected!")
    }

  }

 return(
    <>
    {
    <div className="mx-auto card" style={{width: '18rem'}}>
  <div className="card-body">
    <h5 className="card-title">{props.requestedAmount}</h5>
    <h6 className="card-subtitle mb-2 text-muted">{props.address}</h6>
    <p className="card-text">{props.reason}</p>
    <button className = "btn btn-dark" onClick={createLoanHandler}>Fund</button>
  </div>
</div>
}
    </>
 )
}

export default BorrowerProfile;