import React from "react";
import HIWimg from '../images/transistor-blockchain-crypto.png';


function HowItWorks() {
    return(
        <>
        <div className="container d-grid gap-5">
            <div className="row text-center">
                <h3>How It Works</h3>
            </div>

            <div className="row text-start">
                <div className="col">
                    <p>
                    Connect your wallet! We currently only support Metamask, but we plan to support more wallet providers soon.
                    </p>
                </div>
                <div className= "col-1"></div>
                <div className="col text-center">
                    <img src={HIWimg} />
                </div>
            </div>

            <div className="row text-center">
                <div className="col">
                    <div className="row mx-auto">
                        <h5>If you're borrowing</h5>
                    </div>

                    <div className="row mx-auto">
                        <div className="col text-start">
                            <ol className="">
                                <li>Click borrow, and enter your wallet address, the amount of ETH you want to borrow and why you're borrowing it.</li>
                                <br/>
                                <li>You'll receive a notification when a lender agrees to fund your request, and you can choose to accept or reject their terms.</li>
                                <br/>
                                <li>Once you accept the terms, make sure you return the loan on time!</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className= "d-flex col-1 justify-content-center">
                    <div className="vr" />
                </div>
                <div className="col">
                    <div className="row mx-auto">
                        <h5>If you're lending</h5>
                    </div>

                    <div className="row mx-auto">
                        <div className="col text-start">
                            <ol className="">
                                <li>Click lend, and you'll be able to see a list of requests from people looking to borrow money.</li>
                                <br/>
                                <li>Set your terms. Let the borrower know when you want your money back.</li>
                                <br/>
                                <li>In the event that your borrower doesn't get the money back, you can liquidate the loan, forfeiting the money but ensuring that the
                                    borrow receives a negative reputation for their bad behavior.
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>



        </div>
        </>
    );

}

export default HowItWorks;