import React from "react";
import Navbar from "../components/Navbar";
import NFTCardGroup from "../components/NFTCardGroup";
import { useContract} from "@thirdweb-dev/react";
import { useState, useEffect } from "react";



const Borrow =() =>{
    const {contract, isLoading} = useContract("0x44cA80B70dF8F5F50478c75A2d3F45E96E17cB1F");
    const [own, setOwn] = useState("");
    const [borrowerAddress, setBorrowerAddress] = useState("")
    const [requestedAmount, setRequestedAmount] = useState(0)
    const [reason, setReason] = useState("")
   // const [loading, setLoading] = useState(true);


    useEffect( ()=>{
        const getOwn = async () => {
            const ownerString = await getOwner();
            setOwn(ownerString);
        }
        getOwn()
    }, []);
    
    
    const getOwner = async () => {
        const owner = await contract.call("owner")
        console.log(owner)
        return owner
    }
    

    const whoIsOwn = () => {
        const oString = JSON.stringify(getOwner());
        return oString
    }

    const addressHandler = (event) => {
        setBorrowerAddress(event.target.value)
        console.log(borrowerAddress);
    }

    const amountHandler = (event) => {
        setRequestedAmount(event.target.value)
        console.log(requestedAmount);
    }

    const reasonHandler = (event) => {
        setReason(event.target.value)
        console.log(reason);
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        const result = await  contract.call("createBorrower", borrowerAddress,reason,requestedAmount)
       console.log(result)
    }



    return(
       <>
       <Navbar />
       <div className="mt-5 container">
       <form onSubmit={submitHandler}>
    <div className="mb-3">
        <label for="exampleInputEmail1" className="form-label">Wallet Address</label>
        <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onInput={addressHandler}/>
    <div id="emailHelp" className="form-text">This is so that your address is stored on the smart contract.</div>
    </div>
    <div className="mb-3">
        <label for="exampleInputPassword1" className="form-label">Amount Requested</label>
        <input type="number" className="form-control" id="exampleInputPassword1" onInput={amountHandler}/>
    </div>
    <div className="mb-3">
        <label for="exampleInputPassword1" className="form-label">Why you're borrowing it</label>
        <input type="text" className="form-control" id="exampleInputPassword1" onInput={reasonHandler}/>
        <div id="borrowHelp" className="form-text">Tell us a bit about why you need this money</div>
    </div>
    <div className="mb-3">
        <label for="exampleInputPassword1" className="form-label">Choose the Rep NFT to use a collateral</label>
        <NFTCardGroup />
    </div>

  <button type="submit" className="btn btn-primary">Submit</button>
</form>

     
    </div>


       </>

    )
}

export default Borrow;