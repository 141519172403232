import React from "react";
import Navbar from "../components/Navbar";
import BorrowerProfile from "../components/BorrowerProfile";


function Lend(){
  var dummyData = [
    {
      address: '0xfA8EAb8B6CeE73139c48b7e383d128CB5464b8aa',
      requestedAmount: 10,
      reason: 'need to buy cigarettes'
    },
    {
      address: '0x14667D3Dc8c7d8ae965C648ACb200D2772F904E6',
      requestedAmount: '200',
      reason: 'need to pay my utility bills'
    },
    {
      address: '0xE23B333936aB763D0881794A1D17dfbbCA30025d',
      requestedAmount: '15',
      reason: 'nft drop'
    }
  ]

  const borrowers = dummyData.map((element)=>{
    return <BorrowerProfile address = {element.address} requestedAmount = {element.requestedAmount} reason = {element.reason} />
  })
  
    return(
    <>
    <Navbar />
    <div className="mt-5 d-flex flex-row container">
        {borrowers}

    

    </div>
    </>
  
    )
}

export default Lend;