import React from "react";
import earlyPayment from "../images/1_Early_Payment.jpg";
import fivePayments from "../images/5_On_time_Payments.jpg";
import tenPayments from "../images/10_On_time_Payments.jpg";

import { useState } from "react";



const NFTCardGroup = () => {
    const [leftSelected, setLeftSelected] = useState(false);
    const [midSelected, setMidSelected] = useState(false)
    const [rightSelected, setRightSelected]  = useState(false)

    const clickLeftHandler = () => {
        setLeftSelected(true);
        setMidSelected(false);
        setRightSelected(false);
    }

    const clickMidHandler = () => {
        setLeftSelected(false);
        setMidSelected(true);
        setRightSelected(false);
    }

    const clickRightHandler = () => {
        setLeftSelected(false);
        setMidSelected(false);
        setRightSelected(true);
    }

    return(
      <>
<div className="card-group row row-cols-1 row-cols-md-3 g-4">
<div className="col">

        <div className={leftSelected? 'mx-auto card border-dark': 'mx-auto card'} id="left" onClick = {clickLeftHandler}>
        <img src={earlyPayment} className="card-img-top" alt="..."/>
        <div className="card-body">
        <h5 className="card-title">1 Early Payment</h5>
        </div>
    </div>

  </div>
  <div className="col">
    <div className={midSelected? 'mx-auto card border-dark': 'mx-auto card'} onClick={clickMidHandler}>
        <img src={fivePayments} className="card-img-top" alt="..."/>
        <div className="card-body">
        <h5 className="card-title">5 On time Payments</h5>
        </div>
    </div>
   </div>
   <div className="col">
    <div className={rightSelected? 'mx-auto card border-dark': 'mx-auto card'} onClick={clickRightHandler}>
        <img src={tenPayments} className="card-img-top" alt="..."/>
        <div className="card-body">
        <h5 className="card-title">10 On time Payments</h5>
        </div>
    </div>
    </div>
</div>
</>
)
}


export default NFTCardGroup;