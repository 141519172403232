import React from "react";

const LoansGiven = (props) => {
    return(
        <>
        {
        <div className="mx-auto card" style={{width: '20rem'}}>
      <div className="card-body">
        <h5 className="card-title">To: {props.toAddress}</h5>
        <h6 className="card-subtitle mb-2 text-muted">Amount Given: {props.amountGiven}</h6>
        <p className="card-text">Repayment Date: {props.repaymentDate}</p>
      </div>
    </div>
    }
        </>
     )
}

export default LoansGiven;