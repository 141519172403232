import React from "react";

const createRepaymentHandler = async(event) =>{
    event.preventDefault();
    alert("Repayment made successfully")


  }

const BorrowedLoans = (props) => {
    return(
        <>
        {
        <div className="mx-auto card" style={{width: '20rem'}}>
      <div className="card-body">
        <h5 className="card-title">From: {props.fromAddress}</h5>
        <h6 className="card-subtitle mb-2 text-muted">Amount Borrowed: {props.amountBorrowed}</h6>
        <p className="card-text">Repayment Date: {props.repaymentDate}</p>
        <button className = "self-align-center btn btn-dark" onClick={() => {props.setIsPaid(true)}}>Pay</button>
      </div>
    </div>
    }
        </>
     )
}

export default BorrowedLoans;