import React from "react";
import Navbar from "../components/Navbar";
import { useAddress } from "@thirdweb-dev/react";
import { useState } from "react";
import LoansGiven from "../components/LoansGiven";
import BorrowedLoans from "../components/BorrowedLoans";
import tenPayments from "../images/5_Early_Payments.jpg";


const Profile = () => {
const address = useAddress();
const [isPaid, setIsPaid] = useState(false);

const handleClick = (event) => {
    event.preventDefault();
    setIsPaid(true);
    alert("Repayment made successfully");
}

var borrowedLoansData = [
    {
      from: '0xfA8EAb8B6CeE73139c48b7e383d128CB5464b8aa',
      amountBorrowed: 10,
      repaymentDate: '15/08/2022'
    },
    {
      from: '0x25460676E808c58D239AFe6a49d3b6F4A529a972',
      amountBorrowed: 200,
      repaymentDate: '08/08/2022'
    },
    {
      from: '0x0e35E54Ad52EC534aC4f3628c8C6D6423655964e',
      amountBorrowed: 15,
      repaymentDate: 'Loan Terminated'
    }
  ]

  var loansGivenData= [{
    to: '0xfA8EAb8B6CeE73139c48b7e383d128CB5464b8aa',
    amountGiven: 10,
    repaymentDate: `10/08/2022`
  }]

  const borrowedLoans = borrowedLoansData.map((element)=> {
    return <BorrowedLoans fromAddress = {element.from} amountBorrowed = {element.amountBorrowed} repaymentDate = {element.repaymentDate} setIsPaid = {setIsPaid}/>

  });

  const loansGiven = loansGivenData.map((element)=> {
    return <LoansGiven toAddress = {element.to} amountGiven = {element.amountGiven} repaymentDate = {element.repaymentDate} />
  });

return(
    <>
    <Navbar />
    <div className = "mt-5 container">
        {address ?
        (
            <>
            <div className = "mx-auto mt-5 row">
            <h5>Borrowed Loans</h5>
                {borrowedLoans}
            </div>
            <div className = "mx-auto mt-5 row">
            <h5>Loans Given</h5>
                {loansGiven}
            </div>
            <div className="mx-auto mt-5 row row-cols-1 row-cols-md-3 g-4">  
                   { isPaid &&
                        <div className="col">
                        <div className='mx-auto card'>
                            <img src={tenPayments} className="card-img-top" alt="..."/>
                            <div className="card-body">
                            <h5 className="card-title">5 Early Payments</h5>
                            </div>
                        </div>
                        </div>}
                        </div>
            </>

        )

        :
        (
        <div>
            <p> Please connect your wallet to see your profile </p>
            </div>
        )}
        <div className = "mx-auto mt-5 row">
   
        </div>
    </div>
    </>

)
}

export default Profile;