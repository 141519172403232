import React from "react";
import { Link } from "react-router-dom";

function Hero(){
    return(
        <>
  <div class="px-4 py-5 my-5 text-center">
    <h1 class="display-5 fw-bold">L3nd</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">Lend and borrow small amounts of money using your reputation as collateral. The better you behave, the more you can borrow!</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <Link to="/lend" class="btn btn-primary btn-lg px-4 gap-3">Lend</Link>
        <Link to="/borrow" class="btn btn-outline-secondary btn-lg px-4">Borrow</Link>
      </div>
    </div>
  </div>



        </>
    )
}

export default Hero;