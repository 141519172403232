import React from "react";
import Navbar from "../components/Navbar";
import Hero from '../components/Hero';
import HowItWorks from "../components/HowItWorks";

function Home(){
    return(
        <>
        <Navbar />
        <div class="container-fluid">
           <Hero />
           <HowItWorks />
        </div>
        </>



    )
}

export default Home;